import { Card, Divider, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import  eren  from "./../asset/logos/CRESS-EREN_couleurs_transparent.png"
import universite from "./../asset/logos/Universite Sorbonne Paris Nord.png"
import hopitaux  from "./../asset/logos/hopitaux.png"
import { motion } from 'framer-motion';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
const files = require.context('../../publications', true);
const filesList = files.keys().map(file => {

    let nom = file.split('/')

    let item = {
        'chemin': files(file),
        'nom': nom[1]
    }
    // console.log(item)

    return item
})



export const Publications = () => {
    let navigate = useNavigate();
    const handleChangePage = (page) => {
        if (page)
            navigate(page, { state: page })
    }

    return (
        <>
           

      <div id='wrap_info'>
        {/* <motion.figure   style={{ marginTop: "20px" }} className="table" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2 }}> */} 

         
         <div className='block_item_info titre_info'><h1>Étude Nutri-CIRR</h1></div>

         <div className='block_item_info objectifs'>
            <h2>Quels sont les objectifs de l’étude Nutri-CIRR ?</h2>
            <p>L’étude Nutri-CIRR cherche à décrire les habitudes nutritionnelles 
                (alimentation, consommation d’alcool, activité physique) 
                des personnes présentant une cirrhose hépatique.</p>
         </div>

         <div className='block_item_info participation'>

        <div className='colonne_participation'>
        <h3>Qui peut participer ?</h3>
        <p>Les personnes présentant 
        une cirrhose et ayant accès à une adresse e-mail</p>
        <div className='colonne_participation_modalites'>
            <span className='arraw_icon'><PlayArrowIcon /></span>
            <h5>En quoi consistent les modalités de participation ?</h5>
            <p>4 questionnaires 
            auxquels vous répondrez 1 fois
                pour évaluer :  </p>

                <ul>
                    <li>Votre consommation habituelle des principaux groupes d’aliments</li>
                    <li>Votre activité physique au cours des 7 derniers jours</li>
                    <li>Votre situation familiale, liée à l’emploi, et votre mode de vie (consommation d’alcool, tabagisme)</li>
                    <li>Votre poids et votre taille</li>
                </ul>
        </div>
        </div>

        <div className='colonne_participation'>
        <h3>Comment participer ?</h3>
        <p>La participation se fait en ligne sur le site 
        de l’étude Nutri-CIRR en 2 étapes : </p>

        <ol>
            <li className='ol_li_first'><span className='ol_li_first_span'> Inscription </span>
                <ul>
                    <li>Cliquez sur « je m’inscris » </li>
                    <li>Lisez la note d’information et signez le formulaire</li>
                    <li>Créez votre profil en quelques clics</li>
                </ul>
            </li>
            <li className='ol_li_first'> <span className='ol_li_first_span'> Participation</span>
            <ul>
                <li>Répondez aux questionnaires disponibles en ligne</li>
            </ul>
            </li>
        </ol>
        </div>



         </div>
         <div className='block_item_info qui_coordonne'>
            <h2>Qui coordonne l’étude Nutri-CIRR ?</h2>
         </div>


         <div className='block_item_info partnaires'>
            <div>
            <img  src={universite} alt="université" />
            <p>La recherche est promue par l’Université Sorbonne Paris Nord</p>
            </div>
            <div>   <img  src={eren} alt="Funded by the EU" />
            <p> <span>Elle est coordonnée par l’Équipe de Recherche en Épidémiologie Nutritionnelle </span>
         USPN, INSERM, INRAE, CNAM, Centre de Recherche en Épidémiologie 
            et StatistiqueS (CRESS), Université Paris Cité</p>
            </div>

            <div>
            <img  src={hopitaux} alt="Universite Sorbonne Paris Nord" />
            <p><span>En collaboration avec le Service d'Hépatologie, Hôpital Avicenne Bobigny 93, Assistance Publique-Hôpitaux Publique</span></p>
            </div>
     
       
          
         </div>
         <div className='block_item_info contact'>
      <h2>   Comment nous contacter ? </h2>
         </div>
         <div className='block_item_info renseignements'>
        <small>Pour plus de renseignements, contactez-nous à l’adresse :  <a href='https://etude-nutri-cirr.fr'>etude@nutri-cirr.fr </a> </small>
         </div>
      {/*    </motion.figure>*/}



   
         </div>





{/* 
 <h2>Publications dddd</h2>
            <List>
                {filesList.map((row, index) => (
                    <>
                   
                        <ListItem key={"Publications_"+index}>
                            <ListItemButton onClick={() => handleChangePage(row.chemin)}>
                            
                                <ListItemText sx={{ marginLeft: 1 }} primary={row.nom} />
                            </ListItemButton>

                        </ListItem>
                        <Divider />
                    </>
                ))}
            </List>
            */}

        </>
    )
}
